export const environment = {
    production: true,
    environment: 'staging',
    apiUrl: 'https://staging-archief.boomonderwijs.nl',
    mercureHub: 'https://digitaalonderwijs-stag-mercure/.well-known/mercure',
    fileDownloadUrl: 'https://staging-archief.boomonderwijs.nl/api/nl/file/download/',
    logoutUrl: '/security/keycloak/logout',
    teachingMaterialsUrl: 'https://aanvragen.edu-actief.nl',
    teachingMaterialsUrlBoomvo: 'https://mijn.boomvo.nl',
    maintenanceUrl: '/maintenance.html',
    sentryDsn: 'https://d775bf6873cfaae064eb40b47c66ed2c@o4507333400461312.ingest.de.sentry.io/4507389713055824',
    termsAndConditionsUrl: 'https://webshop.edu-actief.nl/klantenservice/algemene_voorwaarden',
    sentryEnabled: true,
    microsoftAppId: '3b6aa9ad-4b78-4542-8088-2bfee61b8d31',
    microsoftRedirectUrl: 'https://staging-archief.boomonderwijs.nl',
    bboUrl: 'https://boomberoepsonderwijs.nl/contact/contactformulier',
    bvoUrl: 'https://boomvoortgezetonderwijs.nl/contact/',
    boomvoUrl: 'https://staging.boomdigitaal.nl',
    features: {
        teacherMaterialsEnabled: true,
        helpPageEnabled: true,
        onedriveEnabled: false,
        searchEnabled: true
    },
    archiveModeActive: true,
};
